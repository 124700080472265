// apiService.js

let apiUrl = '';
let clientId = '';
let clientSecret = '';
let tokenData = null;

export function setApiConfig(url, id, secret) {
  apiUrl = url;
  clientId = id;
  clientSecret = secret;
}

export function setTokenData(data) {
  tokenData = data;
}

export async function getAccessToken(username, password) {
    try {
      tokenData = await fetchAccessToken(apiUrl, clientId, clientSecret, username, password);
      return tokenData;
    } catch (error) {
      console.error('Error fetching access token:', error);
      throw error;
    }
  }

export async function fetchAccessToken(apiUrl, clientId, clientSecret, username, password) {
    const urlSearchParams = new URLSearchParams({
      client_id: clientId,
      client_secret: clientSecret,
      grant_type: 'password',
      username: username,
      password: password,
    });
  
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: urlSearchParams.toString(),
    };
  
    try {
      const response = await fetch(apiUrl + '/api/token', requestOptions);
      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }
  
      tokenData = await response.json();
      return tokenData;
    } catch (error) {
      console.error("Error fetching access token:", error);
      throw error;
    }
  }


  async function refreshAccessToken(apiUrl, clientId, clientSecret, refreshToken) {
    const urlSearchParams = new URLSearchParams({
      client_id: clientId,
      client_secret: clientSecret,
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
    });
  
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: urlSearchParams.toString(),
    };
  
    try {
      const response = await fetch(apiUrl + '/api/token', requestOptions);
      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error refreshing access token:', error);
      throw error;
    }
  }


  export async function getValidAccessToken(tokenData) {
  const currentTime = new Date().getTime();
  const tokenExpirationTime = new Date(tokenData.issued_at).getTime() + tokenData.expires_in * 1000;

  if (currentTime >= tokenExpirationTime) {
    try {
      const refreshedTokenData = await refreshAccessToken(apiUrl, clientId, clientSecret, tokenData.refresh_token);
      return refreshedTokenData.access_token;
    } catch (error) {
      console.error('Error refreshing access token:', error);
      throw error;
    }
  } else {
    return tokenData.access_token;
  }
}

export async function callApi(endpoint, method = 'GET', body = null) {
    const validAccessToken = await getValidAccessToken(tokenData);
    const headers = new Headers({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${validAccessToken}`,
    });
  
    const options = {
      method,
      headers,
      body: body ? JSON.stringify(body) : null,
    };
  
    const response = await fetch(`${apiUrl}${endpoint}`, options);
  
    if (!response.ok) {
      throw new Error(`Error calling API: ${response.statusText}`);
    }
  
    return await response.json();
  }
  