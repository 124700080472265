<template>
  <AppNavigation>
    <JsonTextOverlay />
  </AppNavigation>
</template>

<script>

import AppNavigation from "./components/AppNavigation.vue";
import JsonTextOverlay from './components/JsonTextOverlay.vue';

export default {
  name: "App",
  components: {
    AppNavigation,
    JsonTextOverlay,
  },
};
</script>