<template>
  <v-navigation-drawer app light temporary v-model="localDrawer">
      <v-list density="compact">
        <v-list-item @click="sendToProject">
        <v-list-item-icon>
          <v-icon>mdi-cloud-upload</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            Send to Project ({{ exportDataCount }})
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>
      <v-list-item
        v-for="(element, index) in localExportData" 
        :key="'menu-' + index"
          link
          @click="removeFromExportData(index, element.id)"
        >
          <v-list-item-icon>
            <v-icon>mdi-map-marker-plus</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ element.Title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>


      </v-list>
    </v-navigation-drawer>
</template>

<script>
export default {
  name: "AppNavigation",
  props: {
    selectedData:Array,
    exportData:Array,
    selectedPosition: String,
    drawer: Boolean
  },
  data() {
    return {
      //localSelectedData:this.selectedData,
      localExportData:this.exportData,
      localSelectedPosition: this.selectedPosition,
      localDrawer:this.drawer
    };
  },
  methods: {
    onPositionChange(value) {
      console.log("Selected position:", value);
      this.localSelectedPosition = value;
      this.$emit('update:selectedPosition', value); // Emit an event to update the prop in the parent component
   
    },
    onMenuItemClick() {
      // Handle menu item click
    },
    sendToProject() {
          console.log("Sending data to project:", this.localExportData.length);
          // Add your logic for sending data to the project here
        },
    removeFromExportData(index) {
      //var selectedDataIndex = this.localSelectedData.find(item=> item.id === this.localExportData[index].id);
      //this.localSelectedData.splice(selectedDataIndex, 1);
      this.localExportData.splice(index, 1);
    },
  },
  computed: {
    exportDataCount() {
      return this.localExportData.length;
    },
  },
  watch: {
    drawer(newVal) {
      // Update localDrawer whenever the prop 'drawer' changes
      console.log("Drawer updated:" + newVal);
      this.localDrawer = newVal;
    },
    localDrawer(newVal) {
      // Emit an event to update the drawer value in the parent component when localDrawer changes
      this.$emit('update:drawer', newVal);
    },
    exportData(newVal) {
      this.localExportData = newVal;
    },
    localExportData(newVal) {
      this.$emit('update:exportData',  newVal);
    },
    selectedData(newVal) {
      this.localSelectedData = newVal;
    },
    localSelectedData(newVal) {
      this.$emit('update:selectedData', newVal);
    }
  },
};
</script>
