export default class ShortId {
    base62chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz".split("");
    generatedIds = new Set();
  
    randomInt(max) {
      return Math.floor(Math.random() * max);
    }
  
    getBase62(length) {
      let result = "";
      for (let i = 0; i < length; i++) {
        result += this.base62chars[this.randomInt(62)];
      }
      return result;
    }
  
    getBase36(length) {
      let result = "";
      for (let i = 0; i < length; i++) {
        result += this.base62chars[this.randomInt(36)];
      }
      return result;
    }
  
    newId(length = 8) {
      let id = this.getBase36(length);
      while (this.generatedIds.has(id)) {
        id = this.getBase36(length);
      }
      this.generatedIds.add(id);
      return id;
    }
  }
  