<template>
    <div>
      <canvas ref="canvas" @wheel.prevent="onWheel" @mousedown="onMouseDown" @mousemove="onMouseMove" @mouseup="onMouseUp" @mouseleave="onMouseLeave"></canvas>
    </div>
  </template>
  
  <script>
  export default {
    props:{
        staticJsonData:Array,
        jsonData:Array,
        selectedData:Array,
        pngDataUrl:String,
        multiselectMode:Boolean
    },
    data() {

        const locationImage = new Image(16, 16);
        locationImage.src = require('@/assets/images/location.png');

        const greenTickImage = new Image(16, 16);
        greenTickImage.src = require('@/assets/images/green-tick.png');

      return {
        canvas: null,
        ctx: null,
        backgroundImg: null,
        bgImage: require('@/assets/images/rdrive-rp-default-bg.jpg'),
        locationImg: locationImage,
        greenTickImg: greenTickImage,
        locations: [],
        state: {
          scale: 1,
          originalScale:1,
          translate: { x: 0, y: 0 },
          dragging: false,
          lastPosition: { x: 0, y: 0 },
          iniitalMouseDownPosition: { x: 0, y: 0}
        },
        savedState:{},
        ctrlPressed:false,
      };
    },
    mounted() {
      this.canvas = this.$refs.canvas;
      this.ctx = this.canvas.getContext("2d");
      this.canvas.width = window.innerWidth;
      this.canvas.height = window.innerHeight;
  
      this.backgroundImg = new Image();
      this.backgroundImg.src = this.bgImage;
      this.backgroundImg.onload = () => {
        this.updateInitialScaleAndTranslation();
        this.draw();
      };
      window.addEventListener('keydown', this.onKeyDown);
      window.addEventListener('keyup', this.onKeyUp);
    },
    beforeDestroy() {
      window.removeEventListener('keydown', this.onKeyDown);
      window.removeEventListener('keyup', this.onKeyUp);
    },

    methods: {
      updateInitialScaleAndTranslation() {
        const scaleX = this.canvas.width / this.backgroundImg.width;
        const scaleY = this.canvas.height / this.backgroundImg.height;
  
        // Set the initial scale to the smaller scale factor
        this.state.scale = Math.min(scaleX, scaleY);
  
        // Center the image on the canvas
        this.state.translate.x = (this.canvas.width - this.backgroundImg.width * this.state.scale) / 2;
        this.state.translate.y = (this.canvas.height - this.backgroundImg.height * this.state.scale) / 2;
      },
      drawLocations() {
        this.jsonData.forEach((location, index) => {
            const isSelected = this.jsonData[index].selected;
            const img = isSelected ? this.greenTickImg : this.locationImg;
            this.ctx.drawImage(
            img,
            location.x - img.width / 2,
            location.y - img.height / 2,
            img.width,
            img.height
            );
        });
      },
      drawTextRectangles() {
        this.ctx.fillStyle = "rgba(0, 128, 0, 0.3)"; // Set the fill style to green with 0.5 opacity
        this.ctx.font = "16px Arial"; // Set the font style for the text
        this.ctx.textBaseline = "top"; // Set the text baseline to top

        this.staticJsonData.forEach((item) => {
          if (item.show) {
            // Draw the text rectangle
            this.ctx.save();

            // Translate the canvas to the rectangle's position
            this.ctx.translate(item.x, item.y - item.height);

            // Rotate the canvas by the rectangle's rotation (in radians)
            const rotationRadians = (item.rotation * Math.PI) / 180;
            this.ctx.rotate(rotationRadians);

            // Draw the text rectangle at (0, 0) since the canvas has been translated
            this.ctx.fillRect(0, 0, item.width, item.height);

            // Restore the canvas state
            this.ctx.restore();
          }
        });
      },
      locationAtPoint(x, y) {
        for (const location of this.jsonData) {
          const dx = x - location.x;
          const dy = y - location.y;
          const distance = Math.sqrt(dx * dx + dy * dy);
  
          if (distance <= 10) {
            return location;
          }
        }
        return null;
      },
      getCanvasOffset() {
        const rect = this.canvas.getBoundingClientRect();
        return {
            x: rect.left,
            y: rect.top
        };
        },
      onWheel(e) {
        e.preventDefault();

        const canvasOffset = this.getCanvasOffset();

        const mouseX = e.clientX - canvasOffset.x;
        const mouseY = e.clientY - canvasOffset.y;
        const zoomAmount = e.deltaY > 0 ? 0.95 : 1.05;

        this.ctx.setTransform(1, 0, 0, 1, 0, 0);
        this.ctx.translate(mouseX, mouseY);
        this.ctx.scale(zoomAmount, zoomAmount);
        this.ctx.translate(-mouseX, -mouseY);
        this.ctx.translate(this.state.translate.x, this.state.translate.y);

        this.state.scale *= zoomAmount;
        this.state.translate.x = (this.state.translate.x - mouseX) * zoomAmount + mouseX;
        this.state.translate.y = (this.state.translate.y - mouseY) * zoomAmount + mouseY;

        // Update state.lastPosition
        //state.lastPosition.x = e.clientX;
        //state.lastPosition.y = e.clientY;

        this.draw();
      },
      onMouseDown(e) {

        const canvasOffset = this.getCanvasOffset();

        const mouseX = (e.clientX - canvasOffset.x - this.state.translate.x) / this.state.scale;
        const mouseY = (e.clientY - canvasOffset.y - this.state.translate.y) / this.state.scale;

        this.state.initialMouseDownPosition = {
          x: (e.clientX - canvasOffset.x - this.state.translate.x) / this.state.scale,
          y: (e.clientY - canvasOffset.y - this.state.translate.y) / this.state.scale
        };

        const selectedLocation = this.locationAtPoint(mouseX, mouseY);
        if (selectedLocation) {
            selectedLocation.dragging = true;
            this.state.lastPosition = { x: mouseX, y: mouseY }; 
        } else {
            this.state.dragging = true;
            this.state.lastPosition = { x: e.clientX, y: e.clientY }; 
        }
      },
      onMouseMove(e) {

        const canvasOffset = this.getCanvasOffset();

        const mouseX = (e.clientX - canvasOffset.x - this.state.translate.x) / this.state.scale;
        const mouseY = (e.clientY - canvasOffset.y - this.state.translate.y) / this.state.scale;

        this.jsonData.forEach((location) => {
            if (location.dragging) {
              if (!this.state.lastPosition.x && !this.state.lastPosition.y) {
                  this.state.lastPosition = { x: mouseX, y: mouseY };
                  return;
              }

              const dx = mouseX - this.state.lastPosition.x;
              const dy = mouseY - this.state.lastPosition.y;
              location.x += dx;
              location.y += dy;
              this.state.lastPosition = { x: mouseX, y: mouseY };
              this.draw();
              console.log('x:' + location.x + ' y:' + location.y);
              return;
            }
        });

        if (this.state.dragging) {
            if (!this.state.lastPosition.x && !this.state.lastPosition.y) {
                this.state.lastPosition = { x: e.clientX, y: e.clientY };
                return;
            }

            const speedFactor = 1 / Math.sqrt(this.state.scale);
            const dx = (e.clientX - this.state.lastPosition.x) * speedFactor;
            const dy = (e.clientY - this.state.lastPosition.y) * speedFactor;

            this.state.translate.x += dx;
            this.state.translate.y += dy;

            this.draw();

            
        } 
        this.state.lastPosition = { x: e.clientX, y: e.clientY };
      },
      onMouseUp(e) {

        this.jsonData.forEach((location) => {
            location.dragging = false;
        });

        this.bounceBack();
  
        this.state.dragging = false;

        const canvasOffset = this.getCanvasOffset();

        // Calculate the mouse position relative to the canvas
        const mouseX = (e.clientX - canvasOffset.x - this.state.translate.x) / this.state.scale;
        const mouseY = (e.clientY - canvasOffset.y - this.state.translate.y) / this.state.scale;

        // Check if any location was clicked
        const clickedLocation = this.locationAtPoint(mouseX, mouseY);

        // If the mouse didn't move significantly since the last mouse down event, it's a click
        const isClick = Math.abs(this.state.initialMouseDownPosition.x - mouseX) < 3 && Math.abs(this.state.initialMouseDownPosition.y - mouseY) < 3;

        if (clickedLocation && isClick) {
            this.locationSelected(clickedLocation);
        }

        this.state.lastPosition = { x: e.clientX - canvasOffset.x, y: e.clientY - canvasOffset.y };

      },
      onMouseLeave() {

        this.jsonData.forEach((location) => {
            location.dragging = false;
        });
        
        this.bounceBack();
        
        this.state.dragging = false;

      },
      onKeyDown(e) {
        if (e.ctrlKey || e.metaKey) {
          // Ctrl key was pressed, perform zooming logic
          //const canvasOffset = this.getCanvasOffset();
          this.ctrlPressed = true;

          const mouseX = this.state.lastPosition.x;
          const mouseY = this.state.lastPosition.y;
          const zoomAmount = 4.0; // Zoom in by 50%

          this.savedState = {
            scale: this.state.scale,
            translate: {...this.state.translate} // Make a copy of the object
          };

          this.ctx.setTransform(1, 0, 0, 1, 0, 0);
          this.ctx.translate(mouseX, mouseY);
          this.ctx.scale(zoomAmount, zoomAmount);
          this.ctx.translate(-mouseX, -mouseY);
          this.ctx.translate(this.state.translate.x, this.state.translate.y);

          this.state.originalScale = this.state.scale;
          this.state.scale *= zoomAmount;
          this.state.translate.x = (this.state.translate.x - mouseX) * zoomAmount + mouseX;
          this.state.translate.y = (this.state.translate.y - mouseY) * zoomAmount + mouseY;

          this.draw();
        }
      },
      onKeyUp(e) {
        console.log(JSON.stringify(e));
        if (this.ctrlPressed && (!e.ctrlKey && !e.metaKey)) {
          if (this.savedState) {
            this.state.scale = this.savedState.scale;
            this.state.translate = this.savedState.translate;

            // Redraw the canvas with the restored state
            this.draw();
          }
          this.ctrlPressed = false;
        }
      },
      bounceBack() {

        const effectiveWidth = this.backgroundImg.width * this.state.scale;
        const effectiveHeight = this.backgroundImg.height * this.state.scale;

        const minX = Math.min(0, this.canvas.width - effectiveWidth);
        const minY = Math.min(0, this.canvas.height - effectiveHeight);

        const maxX = Math.max(0, (this.canvas.width - effectiveWidth) / 2);
        const maxY = Math.max(0, (this.canvas.height - effectiveHeight) / 2);

        const targetTranslateX = Math.min(Math.max(this.state.translate.x, minX), maxX);
        const targetTranslateY = Math.min(Math.max(this.state.translate.y, minY), maxY);

        const transitionDuration = 300; // Duration of the transition in milliseconds
        const startTime = performance.now();

        const animate = (timestamp) => { 
            const elapsedTime = timestamp - startTime;
            const progress = Math.min(elapsedTime / transitionDuration, 1);

            this.state.translate.x = this.state.translate.x + (targetTranslateX - this.state.translate.x) * progress;
            this.state.translate.y = this.state.translate.y + (targetTranslateY - this.state.translate.y) * progress;

            this.draw();

            if (progress < 1) {
                requestAnimationFrame(animate);
            }
        }

        if (targetTranslateX !== this.state.translate.x || targetTranslateY !== this.state.translate.y) {
            requestAnimationFrame(animate);
        }

      },
      draw() {

        this.ctx.setTransform(1, 0, 0, 1, 0, 0);
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.ctx.translate(this.state.translate.x, this.state.translate.y);
        this.ctx.scale(this.state.scale, this.state.scale);
        this.ctx.drawImage(this.backgroundImg, 0, 0, this.backgroundImg.width, this.backgroundImg.height);   
        this.drawTextRectangles();
        this.drawLocations();
      },
      locationSelected(location) {
            const elementId = location.id;
            if (elementId) {
              if (!this.localMultiselectMode) {  
              
                const existingElementIndex = this.selectedData.findIndex(el => el.id === elementId);
              
                if (existingElementIndex !== -1) {
                  this.selectedData.splice(existingElementIndex, 1);
                  location.selected = false;
                } else {
                  const element = this.jsonData.find(el => el.id === elementId);
                  if (element) {
                    this.selectedData.push(element);
                    location.selected = true;
                  }
                }

                const textElementIndex = this.staticJsonData.findIndex(el => el.id === elementId);         
                this.$set(this.staticJsonData[textElementIndex], "show", existingElementIndex === -1);

                this.draw();

              } else {
                const element = this.jsonData.find(el => el.id === elementId);
                this.onLocationSelectedInMultiMode(element);
              }
            }
        },
        onLocationSelectedInMultiMode(location) {
          this.$emit('locationSelectedInMultiMode', location);
        },
    },
    watch: {
        multiselectMode(newVal) {
          this.localMultiselectMode = newVal;
          this.draw();
        },
        selectedData(newVal) {
          this.$emit('update:selectedData', newVal);
          this.draw();
        },
        pngDataUrl(newVal) {         
          this.bgImage = newVal ? newVal : require('@/assets/images/rdrive-rp-default-bg.jpg');
          this.backgroundImg.src = this.bgImage;
          this.draw();
        },
        jsonData() {
          this.draw();
        },
        staticJsonData() {
          this.draw();
        }
    }
  };
  </script>
  