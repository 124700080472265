<template>
  <v-app>
    
    <NavBar :selected-data.sync="selectedData" :export-data.sync="exportData" :selected-position="selectedPosition" :drawer.sync="drawer" />

    <v-app-bar app dark>
      <v-app-bar-nav-icon @click="showExportDataDialog = !showExportDataDialog">
        <v-badge v-if="exportData.length > 0" color="red" :content="exportData.length" overlap>
          <v-icon>mdi-map-marker-outline</v-icon>
        </v-badge>
        <v-icon v-if="exportData.length === 0">mdi-map-marker-outline</v-icon>
      </v-app-bar-nav-icon>
        <v-badge color="blue" :content="'Beta'" overlap>
          <v-app-bar-title>Rapid Plan</v-app-bar-title>  
        </v-badge>  
      <v-spacer></v-spacer>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon @click="showSearchDialog = !showSearchDialog" v-bind="attrs" v-on="on">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </template>
        <span>Location Search</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon :color="multiselectButtonColor" @click="multiselectMode = !multiselectMode" v-bind="attrs" v-on="on">
            <v-icon>mdi-select-multiple-marker</v-icon>
          </v-btn>
        </template>
        <span>Multi-Location Selector</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon :color="positionButtonColor" @click="onShowPositionToolbar" v-bind="attrs" v-on="on">
            <v-icon>mdi-border-radius</v-icon>
          </v-btn>
        </template>
        <span>Location Position</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon :color="mergeButtonColor" @click="onShowMergeToolbar" v-bind="attrs" v-on="on">
            <v-icon>mdi-merge</v-icon>
          </v-btn>
        </template>
        <span>Merge Text</span>
      </v-tooltip>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="showDrawingUpload = !showDrawingUpload">
            <v-list-item-icon>
              <v-icon>mdi-upload</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Upload Drawing</v-list-item-title>
          </v-list-item>
          <!--<v-list-item @click="changeDrawingDimensions">
            <v-list-item-icon>
              <v-icon>mdi-pencil-ruler</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Change Drawing Dimensions</v-list-item-title>
          </v-list-item>-->
          <v-list-item @click="login" v-if="!tokenData">
            <v-list-item-icon>
              <v-icon>mdi-login</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Login</v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout" v-if="tokenData">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    
    <v-main>
      <v-container fluid class="no-padding">

        <v-overlay :value="showLoadingDialog">
          <v-progress-circular
            indeterminate
            size="64"
          ></v-progress-circular>
        </v-overlay>

        <PlanViewer
          :json-data="jsonData" 
          :static-json-data="staticJsonData"
          :selected-position="selectedPosition" 
          :export-data="exportData"
          :selected-data="selectedData" 
          :multiselect-mode="multiselectMode"
          :pngDataUrl="pngDataUrl"
          @locationSelectedInMultiMode="handleLocationSelectedInMultiMode" 
        />
      </v-container>

    </v-main>


    <!-- Multi-select Dialog -->
    <v-dialog v-model="showMultiselectDialog" max-width="800px">
          <v-card>
            <v-card-title>
              Selected Locations ({{ selectedLocationsCount }}):
            </v-card-title>

            <div
              class="scrollable"
              style="overflow-y: auto;"
            >
            <v-simple-table
              fixed-header
              height="600px"
            >
              <template v-slot:default>
                <thead>
                  <tr>                  
                    <th class="text-left">Name</th>
                    <th class="text-left">Height</th>
                    <th class="text-left">X</th>
                    <th class="text-left">Y</th>
                    <th class="text-left">Remove</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in multiselectModeSelectedLocations"
                    :key="item.id"
                  >               
                    <td>{{ item.title }}</td>
                    <td>{{ item.height.toFixed(1) + 'px' }}</td>
                    <td>{{ item.x.toFixed(3) }}</td>
                    <td>{{ item.y.toFixed(3) }}</td>
                    <td>
                    <v-btn icon @click="updateMultiSelectModeSelectedLocations(item)">
                      <v-icon>mdi-map-marker-remove-outline</v-icon>                   
                      </v-btn>
                  </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            </div>

            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="showMultiselectDialog = false">Cancel</v-btn>
            <v-btn color="primary" text @click="addSelectedLocations">Add</v-btn>
          </v-card-actions>
          </v-card>
        </v-dialog>

        

        <!-- Search Dialog -->
        <v-dialog light v-model="showSearchDialog" max-width="800px">
          <v-card>
            <v-card-title>
              Selected Locations ({{ exportData.length }})
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" @click="searchSelectDeselectAll('selectMode')">
                    <v-icon>mdi-check-all</v-icon>
                  </v-btn>
                </template>
                <span>Select All</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" @click="searchSelectDeselectAll('deselectMode')">
                    <v-icon>mdi-close-box-multiple</v-icon>
                  </v-btn>
                </template>
                <span>Deselect All</span>
              </v-tooltip>
            </v-card-title>

            <v-container>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    label="Find Locations:"
                    v-model="searchTerm"
                    type="search"
                    clearable
                    prepend-inner-icon="mdi-magnify"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>

            <div
              class="scrollable"
              style="overflow-y: auto;"
            >    
            <v-simple-table
              fixed-header
              height="600px"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Select</th>     
                    <th class="text-left">Title</th>
                    <th class="text-left">X</th>
                    <th class="text-left">Y</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in filteredJsonData"
                    :key="item.id"
                  >
                  <td>
                    <v-checkbox
                      color="primary"
                      hide-details
                      @change="addRemoveSelectedLocation(item)"
                      :value="item.selected"
                    ></v-checkbox>
                  </td>

                    <td>{{ item.title }}</td>
                    <td>{{ item.x.toFixed(3) }}</td>
                    <td>{{ item.y.toFixed(3) }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>

            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="showSearchDialog = false">Close</v-btn>
          </v-card-actions>
          </v-card>
        </v-dialog>

    <!-- Export Data Dialog -->
    <v-dialog light v-model="showExportDataDialog" :persistent="sendingLocations || uploadFinished" max-width="800px">
      <v-card>
        <v-form ref="exportDataForm" @submit.prevent="validateExportDataForm">
        <v-card-title>
          Export to RDrive ({{ exportData.length }})
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon @click="emptySelectionsAndExportData" v-bind="attrs" v-on="on" v-show="!sendingLocations && !uploadFinished">
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-btn>
            </template>
            <span>Remove All</span>
          </v-tooltip> 
        </v-card-title>


        <v-container v-if="sendingLocations && !uploadFinished" :key="'sendingDrawingOptions'">
          <lottie :options="lottieSendingDrawingsOptions" style="width:200px; height:200px;" />
          <v-row justify="center">
            <v-col cols="auto">
              <v-progress-linear
                :value="progressValue"
                :buffer-value="100"
                style="width:400px"
                color="primary"
                height="6"
                rounded
              >
              </v-progress-linear>
            </v-col>
          </v-row>
        </v-container>

        <v-container v-if="!sendingLocations && uploadFinished" :key="'uploadFinished'">
        <lottie :options="lottieUploadFinished" style="width:300px; height:300px;" />
        <v-row justify="center" align="center">
          <v-col cols="auto">
            <v-btn color="primary" class="mr-2" @click="resetForNextDrawingUpload">
              <v-icon left>mdi-upload</v-icon>
              Upload Drawing
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn @click="viewInRDrive">
              <v-icon left>mdi-open-in-new</v-icon>
              View in RDrive
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

        <v-container v-if="!sendingLocations && !uploadFinished">

        <v-container>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field label="Drawing Name:" v-model="currentDrawingTitle" :rules="drawingNameRules"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                label="Drawing Set:"
                v-model="selectedDrawingSetId"
                :items="drawingSets"
                item-text="title"
                item-value="id" 
                ref="drawingSetSelect"
                :rules="selectRules"
              ></v-select>
            </v-col>
          </v-row>
        </v-container>

        <div
          class="scrollable"
          style="overflow-y: auto;"
        >
          <v-simple-table
            fixed-header
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <!--<th class="text-left">View</th>-->   
                  <th class="text-left">Title</th>
                  <th class="text-left">X</th>
                  <th class="text-left">Y</th>
                  <th class="text-left">Remove</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in exportData"
                  :key="item.id"
                >
                <!--<td>
                  <v-btn icon @click="viewLocation(item)">
                    <v-icon>mdi-map-marker-outline</v-icon>
                  </v-btn>
                </td>-->
                  <td>
                    <v-text-field
                      v-model="item.title"
                      single-line
                      hide-details
                      class="mx-0 my-0"
                    ></v-text-field>
                  </td>
                  <td>{{ item.x.toFixed(3) }}</td>
                  <td>{{ item.y.toFixed(3) }}</td>
                  <td>
                    <v-btn icon @click="removeLocationFromExportData(item)">
                    <v-icon>mdi-map-marker-remove-outline</v-icon>                   
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>

      </v-container>

        <v-card-actions class="sticky">
          <v-spacer></v-spacer>
          <v-btn text @click="showExportDataDialog = false" :disabled="sendingLocations" v-show="!uploadFinished">Cancel</v-btn>
          <v-btn color="primary" text type="submit" :loading="sendingLocations" v-show=" !uploadFinished" :disabled="sendingLocations" >Send</v-btn>
        </v-card-actions>
      </v-form>
      </v-card>
    </v-dialog>







    
        <v-dialog v-model="showLogin" max-width="500" :persistent="!tokenData && !projectCode" light>
          <v-card>
            <lottie v-if="lottieRDriveLogoOptions.animationData" :options="lottieRDriveLogoOptions" />
            <v-card-title v-if="!showProjectsList">Login</v-card-title>
            <v-card-text>
              <template v-if="!showProjectsList">
                <v-form ref="loginForm" @submit.prevent="validateForm">
                  <v-container>
                    <v-row>
                      <v-col cols="1" style="padding-top:20px; padding-left:0px; padding-right:0px; max-width:64px;" class="text-right">https://</v-col>
                      <v-col cols="6">
                        <v-text-field v-model="subdomain" label="Sub-domain" dense required></v-text-field>
                      </v-col>
                      <v-col cols="4" style="padding-top:20px; padding-left:0px; padding-right:4px;" class="text-left">.rdrive.io</v-col>
                    </v-row>
                  </v-container>
                  <v-text-field v-model="username" label="Username" required></v-text-field>
                  <v-text-field v-model="password" label="Password" type="password" required></v-text-field>
                  <v-card-actions align-end>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" :loading="loginLoading" type="submit">Login</v-btn>
                  </v-card-actions>
                </v-form>
              </template>
              <template v-else>
                <v-autocomplete
                  v-model="projectCode"
                  :items="projects"
                  label="Projects"
                  item-text="displayName"
                  item-value="code"
                  :clearable="true"
                  :search-input.sync="search"
                >
                </v-autocomplete>
              </template>
            </v-card-text>
          </v-card>
        </v-dialog>




        <!-- Bottom Navigation -->

        <v-bottom-navigation v-show="showMergeToolbar" fixed grow>
          <v-row justify="center" style="padding:14px;" class="mx-0">
            <v-col cols="auto" class="mx-2">
              <span>Merge Distance ({{ mergeSliderValue }}px)</span>
            </v-col>
            <v-col cols="auto">
              <v-slider style="width:200px" v-model="mergeSliderValue" min="0" max="200" step="1" thumb-label></v-slider>
            </v-col>
            <v-col cols="auto">
              <v-text-field
                  v-model="mergeSliderValue"
                  class="mt-0 pt-0"
                  hide-details
                  single-line
                  type="number"
                  style="width: 60px"
                ></v-text-field>
            </v-col>
            <v-col cols="auto" class="mx-2">
              <span>Separator:</span>
            </v-col>
            <v-col cols="auto">
              <v-text-field v-model="groupedLocationsTitleSeparator" class="mt-0 pt-0" sinyle-line hide-details style="width:60px"></v-text-field>
            </v-col>
          </v-row>
        </v-bottom-navigation>

        <v-bottom-navigation v-show="showPositionToolbar" fixed grow>
          <v-row justify="center" style="padding:14px;" class="mx-0">
            <v-col cols="auto" class="mx-2">
              <span>Top / Bottom ({{ topBottomSliderValue }}px)</span>
            </v-col>
            <v-col cols="auto">
              <v-slider style="width:200px" v-model="topBottomSliderValue" min="-200" max="200" step="1" thumb-label></v-slider>
            </v-col>
            <v-col cols="auto" class="mx-2">
              <span>Left / Right ({{ leftRightSliderValue }}px)</span>
            </v-col>
            <v-col cols="auto">
              <v-slider style="width:200px" v-model="leftRightSliderValue" min="-200" max="200" step="1" thumb-label></v-slider>
            </v-col>
            <v-col cols="auto" style="padding-top: 5px;">
              <v-btn-toggle v-model="selectedPosition" mandatory >
                <v-btn value="Top"> 
                  <v-icon>mdi-arrow-up</v-icon>
                  Top
                </v-btn>
                <v-btn value="Bottom">
                  <v-icon>mdi-arrow-down</v-icon>
                  Bottom
                </v-btn>
                <v-btn value="Left">
                  <v-icon>mdi-arrow-left</v-icon>
                  Left
                </v-btn>
                <v-btn value="Right">
                  <v-icon>mdi-arrow-right</v-icon>
                  Right
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
        </v-bottom-navigation>

        <dropzone-bottom-sheet 
          :text-x-axis-tolerance.sync="textXAxisTolerance"
          :show-drawing-upload.sync="showDrawingUpload" 
          @files-selected="handleFiles" 
        />


  </v-app>
</template>

<script>

import NavBar from "./NavBar.vue";
import PlanViewer from "./PlanViewer.vue";
import DropzoneBottomSheet from './DropzoneBottomSheet.vue';

import { calculateBoundingBox, groupLocationsByDistance } from '@/utils/utils.js'; 
import PdfToPngUtil from '@/utils/pdfToPngUtil';

import Lottie from "vue-lottie";

export default {
  name: "AppNavigation",
  components: {
    NavBar,
    PlanViewer,
    DropzoneBottomSheet,
    Lottie
  },
  props: {
    
  },
  data() {
    return {
      staticJsonData:[], //original payload used for text positioning (never moves) and for resets of jsonData so not to have to go to the server again
      jsonData: [], // current data set updated by dragging and merging
      selectedData: [], // all items selected including items that may have been merged   
      multiselectModeSelectedLocations:[], // temporary list of selected items when using multi-select mode
      exportData:[], // current data to be sent to RDrive - filtered by what exists in jsonData and selectedData
      mergeSliderCurrentValue:0,
      mergeSliderValue:0,
      groupedLocationsTitleSeparator:' ',
      topBottomCurrentSliderValue:0,
      topBottomSliderValue:30,
      leftRightCurrentSliderValue:0,
      leftRightSliderValue:0,

      pngDataUrl:null,

      projects:[],
      projectCode:'',
      currentDrawing:{},
      currentDrawingTitle:'',
      lastUploadedDrawingId:null, //used for the 'View in RDrive' button
      drawingSets:[],
      drawings:[],
      uploadedFilesList:[],
      selectedDrawingSetId:null,
      sendingLocations:false,
      progressValue:0,
      currentLocationIndex:0,
      uploadFinished:false,
      textXAxisTolerance:20,
      
      //show/hides
      drawer: false,
      showMultiselectDialog:false,
      showExportDataDialog:false,
      showSearchDialog:false,
      searchTerm: '',
      multiselectMode:false,
      showMergeToolbar:false,
      showPositionToolbar:false,
      showDrawingUpload:false,
      showLoadingDialog:false,
      
      selectedPosition: 'Top', //mode to calculate original location icon position relative to the bounding box of the text

      showLogin:true,
      showProjectsList:false,

      selectRules: [
        value => !!value || 'A drawing set is required'
      ],
      drawingNameRules: [
        v => !!v || 'Drawing Name is required',
        v => this.isUniqueDrawingTitle(v) || 'Drawing Name must be unique'
      ],
    
      subdomain:'',
      clientId: '',
      clientSecret: '',
      grantType: 'password',
      username: '',
      password: '',
      tokenData:null,
      loginLoading:false,

      lottieRDriveLogoOptions: {
        animationData: null,
        loop: false,
        autoplay: true,
      },
      lottieSendingDrawingsOptions: {
        animationData: null,
        loop: true,
        autoplay: true,
      },
      lottieUploadFinished: {
        animationData: null,
        loop: false,
        autoplay: true,
      },
    };
  },
  methods: {
    mergeLocations() {

      const positionedLocations = this.processLocationPosition(JSON.parse(JSON.stringify(this.staticJsonData)));
      const groups = groupLocationsByDistance(positionedLocations, Number(this.mergeSliderValue));
      const groupedLocations = groups.map(group => calculateBoundingBox(group, this.groupedLocationsTitleSeparator));

      groupedLocations.forEach(groupedLocation => {
        const matchedData = this.selectedData.find(data => data.id === groupedLocation.id);
        if (matchedData) {
          groupedLocation.selected = true;
        }
      });
      return groupedLocations; //only set if the data has changed to prevent bubbling lots of events.      
    },
    onMenuItemClick() {
      // Handle menu item click
    },
    toggleMultiselectDialog() {
      this.showMultiselectDialog = !this.showMultiselectDialog;
      this.multiselectMode = !this.multiselectMode;
    },
    handleLocationSelectedInMultiMode(selectedLocation) {
      this.multiselectModeSelectedLocations = [];
      this.jsonData.forEach((loc) => {
        if (loc.height === selectedLocation.height) {
          loc.selected = true; //default to being selected
          this.multiselectModeSelectedLocations.push(loc);
        }
        this.showMultiselectDialog = true;
      });
    },
    addSelectedLocations() {
      this.multiselectModeSelectedLocations.forEach(item => {
        const itemExists = this.exportData.some(existingItem => existingItem.id === item.id);
        if (!itemExists && item.selected === true) {
          this.exportData.push(item);
        }
        const selectedItemExists = this.selectedData.some(existingItem => existingItem.id === item.id);
        if (!selectedItemExists && item.selected === true) {
          this.selectedData.push(item);
        }
      });
      this.showMultiselectDialog = false;
    },
    updateMultiSelectModeSelectedLocations(item) {
      const index = this.multiselectModeSelectedLocations.findIndex(i => i.id === item.id);
      if (index !== -1) {
        this.multiselectModeSelectedLocations.splice(index, 1);
      }
    },
    addRemoveSelectedLocation(item) {
      const index = this.selectedData.findIndex(i => i.id === item.id);
      if (index !== -1) {
        this.selectedData.splice(index, 1);
      } else {
        this.selectedData.push(item);
      }
      this.jsonData = this.mergeLocations();
    },

    removeLocationFromExportData(item) {
      const index = this.exportData.findIndex(i => i.id === item.id);
      this.exportData.splice(index, 1);
      const selectedIndex = this.selectedData.findIndex(i => i.id === item.id);
      this.selectedData.splice(selectedIndex, 1);
      this.jsonData = this.mergeLocations();
    },
    viewLocation(item) {
        console.log('viewLocation:' + item.title);
    },
    async sendDrawingToProject() {
      this.sendingLocations = true;
      try {
        this.currentDrawing.drawingSetId = this.selectedDrawingSetId;
        this.currentDrawing.title = this.currentDrawingTitle
        if (this.drawings.length === 0) {
          this.drawings = await this.$apiService.callApi('/api/' + this.projectCode + '/drawings/');
        }
        let highestSort = Math.max(...this.drawings
          .filter(drawing => drawing.drawingSetId === this.selectedDrawingSetId)
          .map(drawing => drawing.sort));

        this.currentDrawing.sort = highestSort > 0 ? highestSort + 1 : 0;

        const response = await this.$apiService.callApi('/api/' + this.projectCode + '/drawings', 'POST', [this.currentDrawing]);

        this.progressValue = 50; //50% for the drawing upload
        
        await this.sendLocationsToProject(response);
      } catch (ex)  {
        console.log(JSON.stringify(ex));
        this.sendingLocations = false;
      }   
    },
    async sendLocationsToProject(drawingResponse) {
      if (drawingResponse.length > 0 && drawingResponse[0].id) {
        try {
          this.drawings.push(drawingResponse[0]); //for unique name checking later
          this.lastUploadedDrawingId = drawingResponse[0].id;
          const promises = this.exportData.map(async (item, index) => {
            item.drawingId = this.lastUploadedDrawingId;
            item.id = null; //will be generated by the server.

            await this.$apiService.callApi('/api/' + this.projectCode + '/locations', 'POST', item);

            // Update the progress bar and current location index.
            this.currentLocationIndex = index + 1;
            this.progressValue = (((this.currentLocationIndex / this.exportData.length) * 100) * 0.5) + 50;
      
          });

          await Promise.all(promises);

          this.uploadFinished = true;
 
        } catch (ex) {
          console.log(JSON.stringify(ex));

        } finally {
          //reset these values in case we are trying again or done.          
          this.progressValue = 0;
          this.currentLocationIndex = 0;
          this.sendingLocations = false;
        }
      }
    },
    processLocationPosition(locations) {
      locations.forEach((element) => {             
        var top = element.y;// + element.FontSize * 0.5 - 4;
        var left = element.x;
        if (this.selectedPosition === 'Bottom') {
          left += (element.width / 2) - this.leftRightSliderValue;
          top += this.topBottomSliderValue;
        } else if (this.selectedPosition === 'Top') {
          left += (element.width / 2) - this.leftRightSliderValue;
          top -= this.topBottomSliderValue;
        } else if (this.selectedPosition === 'Right') {
          left += element.width + this.leftRightSliderValue;
          top -= (element.height / 2) + this.topBottomSliderValue; 
        } else if (this.selectedPosition === 'Left') {
          left -= this.leftRightSliderValue;
          top -= this.topBottomCurrentSliderValue;
        }

        element.x = left;
        element.y = top;

      });

      return locations;
    },
    onShowPositionToolbar() {
      this.showPositionToolbar = !this.showPositionToolbar;
      this.showMergeToolbar = false;
    },
    onShowMergeToolbar() {
      this.showMergeToolbar = !this.showMergeToolbar;
      this.showPositionToolbar = false;
    },
    validateForm() {
      if (this.$refs.loginForm.validate()) {
        this.login();
      }
    },
    validateExportDataForm() {
      if (this.$refs.exportDataForm.validate()) {
        this.sendDrawingToProject()
      }
    },
    async login() {
      this.loginLoading = true;
      this.$apiService.setApiConfig('https://api-' + this.subdomain + '.rdrive.io', this.clientId, this.clientSecret)
      this.tokenData = await this.$apiService.getAccessToken(this.username, this.password);
      this.projects =  this.tokenData.projects.map(item => {
        return { code: item.code, displayName: item.displayName };
      });
      this.showProjectsList = true; 
      this.loginLoading = false;
    },
    logout() {
      //
        this.tokenData = null;
        this.projectCode = null;
        this.projects = [];
        this.showProjectsList = false; 
        this.drawings = null;
        this.drawingSets = null;

        this.$nextTick(() => { // there is a watch() on projectCode
          this.showLogin = true;
        });
    },
    async getDrawingSets() {
      const response = await this.$apiService.callApi('/api/' + this.projectCode + '/drawing-sets');
      var drawingSets = response.map(item => {
        return { id: item.id, title: item.title };
      });
      return drawingSets.sort((a, b) => (a.title > b.title) ? 1 : -1);
    },
    changeDrawingDimensions() {
      // Implement logic to change drawing dimensions here
      console.log("Change Drawing Dimensions clicked");
    },
    async handleFiles(files) {
      this.uploadedFilesList = files;
      await this.loadPdf(0);
    },
    async loadPdf(fileIndex) {
      if (this.uploadedFilesList[fileIndex]) {

        this.showLoadingDialog = true;
        
        const reader = new FileReader();
        
        // Closure to capture the file information.
        reader.onload = async (e) => {
            // e.target.result contains the ArrayBuffer data of the file.
            const fileData = e.target.result;

            // Instantiate the PdfToPngUtil class
            const pdfToPngUtil = new PdfToPngUtil(this.textXAxisTolerance);

            // Process the file data here
            const pageNumber = 1;
            const targetWidth = 9933;
            const targetHeight = 7016;

            const result = await pdfToPngUtil.processPdf(fileData, pageNumber, targetWidth, targetHeight, this.uploadedFilesList[fileIndex].name);
            this.currentDrawing = result.drawing;
            this.currentDrawingTitle = this.uploadedFilesList[fileIndex].name.replace('.pdf', '');
            this.pngDataUrl = this.currentDrawing.url;

            this.staticJsonData = result.locations.sort((a, b) => {
              const titleA = a.title.toUpperCase(); // Ignore case
              const titleB = b.title.toUpperCase(); // Ignore case

              if (titleA < titleB) {
                return -1;
              }
              if (titleA > titleB) {
                return 1;
              }

              // Titles are equal
              return 0;
            });

            this.staticJsonData.forEach((element) => {
              element.show = false;
            });
            this.jsonData = this.mergeLocations();

            this.showLoadingDialog = false;
        };
        
        // Read in the file as an ArrayBuffer.
        reader.readAsArrayBuffer(this.uploadedFilesList[fileIndex]);

      }
    },
    emptySelectionsAndExportData() {
      this.staticJsonData.forEach(item => {
        item.show = false;
      });
      this.exportData = [];
      this.selectedData = [];
      this.multiselectModeSelectedLocations = [];
      this.jsonData = this.mergeLocations();
    },
    async loadAnimationData() {
      try {
        const response = await fetch("/animations/rdrive.json");
        const animationData = await response.json();
        this.lottieRDriveLogoOptions.animationData = animationData;

        const response2 = await fetch("/animations/jumping-location.json");
        const animationData2 = await response2.json();
        this.lottieSendingDrawingsOptions.animationData = animationData2;

        const response3 = await fetch("/animations/finished.json");
        const animationData3 = await response3.json();
        this.lottieUploadFinished.animationData = animationData3;       

      } catch (error) {
        console.error("Error fetching Lottie animation:", error);
      }
    },
    viewInRDrive() {
      const url = 'https://' + this.subdomain + '.rdrive.io/' + this.projectCode + '/plan-viewer/' + this.lastUploadedDrawingId; // Replace this with your actual RDrive URL
      window.open(url, '_blank');
      this.resetForNextDrawingUpload();
      
    },
    resetForNextDrawingUpload() {

      this.sendingLocations = false;

      this.staticJsonData = [];
      this.jsonData = [];
      this.emptySelectionsAndExportData();
      this.showExportDataDialog = false;
      this.uploadFinished = false;

      this.showDrawingUpload = true;

      this.pngDataUrl = null,

      this.currentDrawing = {};
      this.currentDrawingTitle = '';
      this.lastUploadedDrawingId = null;
      
      this.showDrawingUpload = true;
    },
    isUniqueDrawingTitle(title) {
      if (this.selectedDrawingSetId) {
        return !this.drawings.some(drawing => drawing.title === title && drawing.drawingSetId === this.selectedDrawingSetId);
      } else {
        return true;
      }
    },
    searchSelectDeselectAll(mode) {
      const searchTerm = this.searchTerm.toLowerCase();
      this.jsonData.forEach((item) => {
        if (item.title.toLowerCase().includes(searchTerm)) {
          const index = this.selectedData.findIndex(i => i.id === item.id);
          const staticItem = this.staticJsonData.find(data => data.id === item.id);
          if (index !== -1 && mode !== 'selectMode') {
            this.selectedData.splice(index, 1);
            staticItem.show = false;
          } else if (mode === 'selectMode') {
            this.selectedData.push(item);
            staticItem.show = true;
          }
        }
      });
      this.jsonData = this.mergeLocations();
    },
  },
  computed: {
    multiselectButtonColor() {
      return this.multiselectMode ? "primary" : "default";
    },
    positionButtonColor() {
      return this.showPositionToolbar ? "primary" : "default";
    },
    mergeButtonColor() {
      return this.showMergeToolbar ? "primary" : "default";
    },
    selectedLocationsCount() {
      return this.multiselectModeSelectedLocations.filter(item => item.selected).length;
    },
    filteredJsonData() {
      if (!this.searchTerm) {
        return this.jsonData;
      }
      const searchTerm = this.searchTerm.toLowerCase();
      return this.jsonData.filter((item) =>
        item.title.toLowerCase().includes(searchTerm)
      );
    },
    
  },
  watch: {
    localMultiselectMode(newValue) {
      this.$emit('update:multiselectMode', newValue);
    },
    mergeSliderValue() {
      if (Number(this.mergeSliderCurrentValue) !== Number(this.mergeSliderValue)) {
        this.mergeSliderCurrentValue = this.mergeSliderValue;
        this.jsonData = this.mergeLocations();
      }
    },
    topBottomSliderValue() {
      if (Number(this.topBottomCurrentSliderValue) !== Number(this.topBottomSliderValue)) {
        this.topBottomCurrentSliderValue = this.topBottomSliderValue;
        this.jsonData = this.mergeLocations();
      }
    },
    leftRightSliderValue() {
      if (Number(this.leftRightCurrentSliderValue) !== Number(this.leftRightSliderValue)) {
        this.leftRightCurrentSliderValue = this.leftRightSliderValue;
        this.jsonData = this.mergeLocations();
      }
    },
    jsonData(newVal) {
      var filteredItems = newVal.filter(item => {
        return this.selectedData.find(jsonItem => jsonItem.id === item.id) !== undefined;
      });
      this.exportData = filteredItems;
    },

    selectedData(newVal) {
      var filteredItems = newVal.filter(item => {
        return this.jsonData.find(jsonItem => jsonItem.id === item.id) !== undefined;
      });
      this.exportData = filteredItems;
    },
    exportData(newVal) {
      this.$emit('update:exportData', newVal);
    },
    selectedPosition() {
      this.jsonData = this.mergeLocations();
    },
    async projectCode() {
      this.showLogin = false;
      if (this.projectCode) {
        this.drawingSets = await this.getDrawingSets();
        if (this.drawingSets.length === 1) {
          this.selectedDrawingSetId = this.drawingSets[0].id;
        }
        this.drawings = await this.$apiService.callApi('/api/' + this.projectCode + '/drawings'); 
        this.showProjectsList = false;
        this.showDrawingUpload = true;
      } else {
        this.drawingSets = [];
      }
    },
  },
  created() {
    this.loadAnimationData();
  }

};
</script>

<style>
.no-padding {
    padding: 0 !important;
}
.sticky {
  position: sticky;
  bottom: 0;
  background-color: white;
  z-index: 2;
  padding: 8px 16px;
}
</style>
