import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import 'material-design-icons-iconfont/dist/material-design-icons.css'

import App from "./App.vue";
import * as apiService from './utils/apiService';

Vue.config.productionTip = false;
Vue.prototype.$apiService = apiService;

Vue.use(Vuetify);

new Vue({
  el: "#app",
  vuetify: new Vuetify({
    theme: {
      dark: true,
    },
  }),
  created() {
    document.title = 'RDrive - Rapid Plan';
  },
  render: (h) => h(App),
});
