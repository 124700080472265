<template>
  <v-bottom-sheet v-model="localShowDrawingUpload" light>
    <v-card flat>
      <v-card-title>Select Drawings</v-card-title>
      <vue-dropzone
        ref="myVueDropzone"
        id="dropzone"
        :options="dropzoneOptions"
        @vdropzone-file-added="onSuccess"
      ></vue-dropzone>
      <v-card-actions>
        <v-row no-gutters>
          <v-col cols="auto" class="mx-2">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">Text Join Tolerance ({{ localTextXAxisTolerance }}px)</span>
              </template>
              <span>Used to join text on a different x-axis (helps with capital letters etc.)</span>
            </v-tooltip>
              
            </v-col> 
          <v-col cols="1">
            <v-slider v-model="localTextXAxisTolerance" min="0" max="200" step="1" thumb-label></v-slider>
          </v-col>
          <v-col>
            <v-spacer></v-spacer>
          </v-col>
          <v-col cols="auto">
            <v-btn @click="localShowDrawingUpload = false">Close</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-bottom-sheet>
</template>


<style scoped>
.small-slider {
  width: 100px;
}
</style>


  
  <script>
  import vue2Dropzone from 'vue2-dropzone';
  import 'vue2-dropzone/dist/vue2Dropzone.min.css';
  
  export default {
    components: {
      vueDropzone: vue2Dropzone,
    },
    data() {
      return {
        localShowDrawingUpload:this.showDrawingUpload,
        localTextXAxisTolerance:this.textXAxisTolerance,
        dropzoneOptions: {
            autoProcessQueue: false, // Disable auto uploading
            thumbnailWidth: 150,
            maxFilesize: 2, // Maximum file size in MB
            headers: { 'My-Awesome-Header': 'header-value' },
            url:'/',
            acceptedFiles:'application/pdf'
        },
      };
    },
    props: {
        showDrawingUpload:Boolean,
        textXAxisTolerance:Number
    },
    methods: {
        onSuccess() {
            const files = this.$refs.myVueDropzone.getAddedFiles();  
            const filteredFiles = files.filter(file => file.type === 'application/pdf');  
            this.$emit('files-selected', filteredFiles);
            this.$refs.myVueDropzone.removeAllFiles(true); // Clear the selected files
            this.localShowDrawingUpload = false;
        },
    },
    watch: {
        showDrawingUpload(newVal) {
            this.localShowDrawingUpload = newVal;
        },
        localShowDrawingUpload(newVal) {
            this.$emit('update:showDrawingUpload', newVal);
        },
        localTextXAxisTolerance(newVal) {
          this.$emit('update:textXAxisTolerance', newVal);
        }
    }
  };
  </script>

<style scoped>
.small-slider {
  width: 100px;
}
</style>
  