import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf';
pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.5.141/pdf.worker.min.js';

import ShortId from '@/utils/ShortId';

export default class PdfToPngUtil {
  constructor(tolerance = 50, rotationTolerance = 2) {
    this.tolerance = tolerance;
    this.ShortId = new ShortId();
    this.rotationTolerance = rotationTolerance;

  }

  calculateScale(page, targetWidth, targetHeight) {
    const viewport = page.getViewport({ scale: 1 });
    const scaleX = targetWidth / viewport.width;
    const scaleY = targetHeight / viewport.height;
    return Math.min(scaleX, scaleY);
  }

  async getTextContentAndPositions(page, scale) {
    const textContent = await page.getTextContent();
    const viewport = page.getViewport({ scale });
    //const transformMatrix = pdfjsLib.Util.transform(viewport.transform, scale, -scale);
    
    let textItems = textContent.items
      .filter((item) => item.str.trim().length > 0)
      .map((item) => {
        const transformedPoint = pdfjsLib.Util.transform(viewport.transform, item.transform);
        const x = transformedPoint[4];
        const y = transformedPoint[5];
        const width = item.width * scale;
        const height = item.height * scale;

        var angle = Math.atan2(transformedPoint[1], transformedPoint[0]);
        // angle now holds the rotation in radians, convert it to degrees if needed
        var angleInDegrees = angle * (180 / Math.PI);


        return {
          id: this.ShortId.newId(),
          x,
          y,
          width,
          height,
          title: item.str,
          externalReference: '',
          createdDate: new Date(),
          dragging: false,
          selected: false,
          rotation: angleInDegrees,
        };
      });


  
    // Join text elements that are on the same y-axis within the tolerance
      textItems = textItems.reduce((joinedItems, currentItem) => {
        const existingItemIndex = joinedItems.findIndex((item) => {
        const yDistance = Math.abs(item.y - currentItem.y);
        const xDistance = Math.abs(item.x + item.width - currentItem.x);
    
        // Check if both currentItem and existingItem have a rotation of zero (or close to zero)
        const isRotationZero = Math.abs(currentItem.rotation) <= this.rotationTolerance && Math.abs(item.rotation) <= this.rotationTolerance;
    
        return yDistance <= this.tolerance && xDistance <= this.tolerance && isRotationZero;
      });
  
      if (existingItemIndex > -1) {
        joinedItems[existingItemIndex].title += currentItem.title;
        joinedItems[existingItemIndex].width += currentItem.width;
      } else {
        // Check if currentItem has the same x, y, and title as an existing item in joinedItems
        const duplicateIndex = joinedItems.findIndex((item) => {
          return item.title === currentItem.title && item.x === currentItem.x && item.y === currentItem.y;
        });
  
        // Add currentItem to joinedItems only if it's not a duplicate
        if (duplicateIndex === -1) {
          joinedItems.push(currentItem);
        }
      }
  
      return joinedItems;
    }, []);
  
    return textItems;
  }
  
  

  async processPdf(pdfArrayBuffer, pageNumber/*, targetWidth, targetHeight*/) {
    const pdfDoc = await pdfjsLib.getDocument({ data: pdfArrayBuffer }).promise;
    const page = await pdfDoc.getPage(pageNumber);
  
    const scale = 3;//this.calculateScale(page, targetWidth, targetHeight);
    const viewport = page.getViewport({ scale });
  
    const canvas = document.createElement('canvas');
    canvas.width = viewport.width;
    canvas.height = viewport.height;
  
    const renderContext = {
      canvasContext: canvas.getContext('2d'),
      viewport,
    };
  
    await page.render(renderContext).promise;
    const pngDataUrl = canvas.toDataURL('image/png');
    const locations = await this.getTextContentAndPositions(page, scale);

    const drawing = {
      id:null,
      title:null,
      isDefault:false,
      sort:3,
      syncedDate:'',
      createdDate:'',
      drawingSetId:null,
      drawingSetTitle:'',
      url:pngDataUrl,
      thumbnailUrl:'',
      createdByTeam:'',
      createdByTeamId:'',
      createdByUser:'',
      createdByUserId:'',
      issueCount:0,
      tags:[]
    };
  
    return { drawing, locations };
  }
  
}
