// utils.js

// Returns false if there are any elements that are not present in both input arrays, based on their 'id' property.
export function haveSameIdObjects(arr1, arr2) {
    if (arr1.length !== arr2.length) {
        return false;
    }

    const diff1 = arr1.filter(item1 => !arr2.some(item2 => item1.id === item2.id));
    const diffCount = diff1.length;
    return diffCount === 0;
}


    
// Calculates the bounding box for a given group of elements and returns an object with the properties of the bounding box.
export function calculateBoundingBox(group, separator) {
    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;
    let title = '';

    group.forEach(element => {
        minX = Math.min(minX, element.x);
        minY = Math.min(minY, element.y);
        maxX = Math.max(maxX, element.x + element.width);
        maxY = Math.max(maxY, element.y + element.height);
        title += title.length > 0 ? separator + element.title : element.title;
    });

    return {
        x: minX,
        y: minY,
        width: maxX - minX,
        height: maxY - minY,
        title:title,
        id:group[0].id,
        drawingId:group[0].DrawingId,
        externalReference: group[0].ExternalReference,
        createdDate: group[0].CreatedDate,
        fontSize: group[0].FontSize,
    };
}

    // Groups elements in the input array based on the specified distance, creating a 2D array of grouped elements.
    export function groupLocationsByDistance(arr, distance) {    
        const groups = [];
        const visited = new Set();
        
        function arePropertiesClose(a, b) {
            return Math.abs(a.x - b.x) < distance && Math.abs(a.y - b.y) < distance;
        }
        
        function dfs(index, currentGroup) {
            if (visited.has(index)) return;
            visited.add(index);
            currentGroup.push(arr[index]);
        
            const sortedNeighbors = [];
            for (let i = 0; i < arr.length; i++) {
                if (i !== index && !visited.has(i) && arePropertiesClose(arr[index], arr[i])) {
                    sortedNeighbors.push({ index: i, y: arr[i].y });
                }
            }
    
            // Sort the connected elements by their .y property in ascending order before exploring them
            sortedNeighbors.sort((a, b) => a.y - b.y);
    
            // Explore the connected elements in the sorted order
            for (const neighbor of sortedNeighbors) {
                dfs(neighbor.index, currentGroup);
            }
        }
        
        for (let i = 0; i < arr.length; i++) {
            if (!visited.has(i)) {
                const currentGroup = [];
                dfs(i, currentGroup);
    
                currentGroup.sort((a, b) => {
                    if (a.y === b.y) {
                        return a.x - b.x;
                    }
                    return a.y - b.y;
                });
            
                groups.push(currentGroup);
            }
        }
        
        return groups;
    }
    