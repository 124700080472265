<template>
  <div id="app">
    
    <div id="image-container">
      <img
        :src="bgImage"
        alt="background image"
        @load="onImageLoad"
        @mousedown="startImageScroll"
        @mousemove="scrollImage"
        @mouseup="stopImageScroll"
        @mouseleave="stopImageScroll"
      />

      <img
        v-for="(element) in jsonData"
        :key="'element-' + element.id"
        :data-id="element.id"
        src="location.png"
        :style="generateDotStyle(element)"
        @mousedown="startDragging(element, $event)"
        @mouseup="stopDragging"
        @click="toggleImage"
      />
      <div
        v-for="(element) in staticJsonData"
        :key="'text-' + element.id"
        :id="'text-' + element.id"
        class="text-element"
        :class="{ highlight: element.highlight }"
        :style="generateTextStyle(element)"
      >
        {{ element.title }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bgImage: 'page-1.png',
      jsonData: [],
      staticJsonData: [],
      dragging: null,
      exportData: [],
      selectedPosition: 'Bottom',
      scrollingImage: false,
      lastMousePosition: { x: 0, y: 0 },
    };
  },
  computed: {
    exportDataCount() {
      return this.exportData.length;
    },
  },
  methods: {
        startImageScroll(event) {
          this.scrollingImage = true;
          this.lastMousePosition = { x: event.clientX, y: event.clientY };
          event.preventDefault();
        },

        scrollImage(event) {
          if (this.scrollingImage) {
            const deltaX = this.lastMousePosition.x - event.clientX;
            const deltaY = this.lastMousePosition.y - event.clientY;

            window.scrollBy(deltaX, deltaY);

            this.lastMousePosition = { x: event.clientX, y: event.clientY };
          }
        },

        stopImageScroll() {
          this.scrollingImage = false;
        },
        removeFromExportData(index, elementId) {
          this.exportData.splice(index, 1);

          const imgElement = this.$el.querySelector(`[data-id="${elementId}"]`);
          if (imgElement) {
            imgElement.src = 'location.png';
          }

          const textElement = this.$el.querySelector(`#text-${elementId}`);
          if (textElement) {
            textElement.style.color = 'red';
          }

          console.log(JSON.stringify(this.exportData));
        },
        sendToProject() {
          console.log("Sending data to project:", this.exportData);
          // Add your logic for sending data to the project here
        },
        toggleImage: function(event) {
          const imgElement = event.target;
          const elementId = imgElement.dataset.id;
          const existingElementIndex = this.exportData.findIndex(el => el.id === elementId);
          if (existingElementIndex !== -1) {
            this.exportData.splice(existingElementIndex, 1);
          } else {
            const element = this.jsonData.find(el => el.id === elementId);
            if (element) {
              this.exportData.push(element);
            }
          }
          imgElement.src = imgElement.src.endsWith('location.png') ? 'green-tick.png' : 'location.png';

          // Find the associated text element
          const textElement = this.$el.querySelector(`#text-${elementId}`);
          if (textElement) {
            // Toggle the color between green and red
            textElement.style.color = textElement.style.color === 'green' ? 'red' : 'green';
          }

          console.log(JSON.stringify(this.exportData));
        },
        generateDotStyle: function(element) {

          //left
          let leftPosition = element.x + 200 - 10 - 8;
          let topPosition = element.y + element.FontSize * 0.5 - 4;

          if (this.selectedPosition === 'Bottom') {
            leftPosition += (element.width / 2);
            topPosition += 20;
          } else if (this.selectedPosition === 'Top') {
            leftPosition += (element.width / 2);
            topPosition -= 20;
          } else if (this.selectedPosition === 'Right') {
            leftPosition += element.width;

          }

          return {
            position: 'absolute',
            left: leftPosition + 'px',
            top: topPosition + 'px',
            width:'16px',
            height:'16px'
          };
    },
        startDragging: function(element, event) {
          this.dragging = element;
          const imageContainer = this.$el.querySelector('#image-container');
          this.offsetX = imageContainer.offsetLeft;
          this.offsetY = imageContainer.offsetTop;
          event.preventDefault();
          document.addEventListener('mousemove', this.drag);
          document.addEventListener('mouseup', this.stopDragging);
        },
        drag: function(event) {
          if (this.dragging) {
            const scrollX = window.scrollX || window.pageXOffset;
            const scrollY = window.scrollY || window.pageYOffset;
            this.dragging.x = event.clientX - this.offsetX + scrollX;
            this.dragging.y = event.clientY - this.offsetY + scrollY;
            const draggedElement = this.jsonData.find(el => el.id === this.dragging.id);

            const existingElementIndex = this.exportData.findIndex(el => el.id === this.dragging.id);
            if (existingElementIndex === -1 && draggedElement) {
              this.exportData.push(draggedElement);
            }

            // Change the image src to 'green-tick.png'
            const imgElement = this.$el.querySelector(`[data-id="${this.dragging.id}"]`);
            if (imgElement) {
              imgElement.src = 'green-tick.png';
            }

            const textElement = this.$el.querySelector(`#text-${this.dragging.id}`);
            if (textElement) {
              textElement.style.color = 'green';
            }
            
            if (draggedElement) {
              //console.log(JSON.stringify(draggedElement));
              draggedElement.x = this.dragging.x;
              draggedElement.y = this.dragging.y;
            }
          }
        },
        stopDragging: function() {
          this.dragging = null;
          document.removeEventListener('mousemove', this.drag);
          document.removeEventListener('mouseup', this.stopDragging);
          console.log(JSON.stringify(this.exportData));
        },
        generateTextStyle: function(element) {
          return {
            left: element.x + 200 + 'px',
            top: element.y - 8 + 'px',
            fontSize: element.FontSize + 'px'
          };
        },
        onImageLoad: function(event) {
          this.$el.querySelector('#image-container').style.width = event.target.naturalWidth + 'px';
          this.$el.querySelector('#image-container').style.height = event.target.naturalHeight + 'px';
        },
        highlightElements: function(fontSize) {
          this.jsonData.forEach(element => {
            element.highlight = element.FontSize === fontSize;
          });
        }
  },
  async created() {
    try {
          const response = await fetch('page-1-output.json');
          if (response.ok) {
            this.jsonData = await response.json();
            this.staticJsonData = JSON.parse(JSON.stringify(this.jsonData)); // Deep copy the JSON data        
          } else {
            console.error('Error loading JSON data:', response.statusText);
          }
        } catch (error) {
          console.error('Error fetching JSON data:', error);
        }
  },
};
</script>

<style scoped>
  #app {
      display: flex;
      position: absolute;
    }
    #side-menu {
      position: fixed;
      left: 0;
      top: 0;
      width: 200px;
      height: 100%;
      overflow-y: scroll;
      border-right: 1px solid #ccc;
      padding: 10px;
      background-color: #fff;
      z-index: 10000;
    }
    #side-menu button {
      display: block;
      width: 100%;
      text-align: left;
      margin-bottom: 5px;
    }
    #image-container {
      margin-left: 200px;
    }
    img {
      width: 100%;
      height: 100%;
      display: block;
      z-index: 1000;
    }
    .text-element {
      position: absolute;
      font-family: Arial, Helvetica, sans-serif;
      color: red;
      z-index: 10;
    }
    .dragging {
      color: green;
    }
    .highlight {
      color: blue;
    }
    .draggable-dot {
      position: absolute;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: rgb(0, 26, 255);
      cursor: move;
    }
</style>
